import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useMainContext from '../Context/useMainContext';
import {
	fetchAllSubCategoryByThirdFamily,
	fetchArticles,
} from '../../Macrosoft_API';

export function MenuLat() {
	const [openItems, setOpenItems] = useState({});
	const {
		subCategories,
		groups,
		setGroups,
		categories,
		setSectionSelected,
		articles,
		setArticles,
		CodCliente
	} = useMainContext();

	useEffect(() => {
		console.log('data recibida en menu lat:', subCategories);
	}, [subCategories]);

	const handleClick = (family, subFamily) => {
		setOpenItems((prev) => ({ ...prev, [subFamily]: !prev[subFamily] }));
		if (!openItems[subFamily]) {
			handleGetGroups(family, subFamily);
		}
	};

	const handleGetGroups = async (family, subFamily) => {
		setSectionSelected('ShopNavigator');
		try {
			const params = {
				subFamily: subFamily,
				family: categories,
				listaPrecio: '1',
				page: '1',
			};
			const response = await fetchAllSubCategoryByThirdFamily(params);
			if (response?.data) {
				setGroups((prev) => ({ ...prev, [subFamily]: response.data }));
			}
		} catch (error) {
			console.log('hubo un error en el fetch:', error);
		}
	};

	const handleGetArticles = async (subFamily, CodStock) => {
		console.log('Sub Family:', subFamily);
		try {
			const params = {
				subFamily: subFamily,
				family: categories,
				CodStock: CodStock,
				CodCliente: CodCliente,
				page: '1',
			};
			const response = await fetchArticles(params);
			console.log('respuesta desde la funcion: ', response);
			if (response) {
				console.log(' se guarda el dato');
				setArticles(response);
			}
		} catch (error) {
			console.log('hubo un error en el fetch:', error);
		}
	};

	return (
		<Box
			className='---MenuLat.js---'
			sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
		>
			<List
				component='nav'
				aria-label='main categories'
			>
				{subCategories?.map((item) => (
					<React.Fragment key={item.Grupo}>
						<ListItemButton onClick={() => handleClick(item.ref, item.Grupo)}>
							<ListItemIcon>
								{openItems[item.Grupo] ? <ExpandLess /> : <ExpandMore />}
							</ListItemIcon>
							<ListItemText primary={item.Descripcion} />
						</ListItemButton>
						<Collapse
							in={openItems[item.Grupo]}
							timeout='auto'
							unmountOnExit
						>
							<List
								component='div'
								disablePadding
							>
								{groups[item.Grupo]?.map((Element) => (
									<ListItemButton
										key={Element.CodStock}
										onClick={() =>
											handleGetArticles(item.Grupo, Element.CodStock)
										}
										sx={{ pl: 4 }}
									>
										<ListItemIcon>
											<ArrowRightIcon />
										</ListItemIcon>
										<ListItemText primary={Element.Articulo} />
									</ListItemButton>
								))}
							</List>
						</Collapse>
					</React.Fragment>
				))}
			</List>
			<Divider />
		</Box>
	);
}
