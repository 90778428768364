import Typography from '@mui/joy/Typography';
import { Grid } from '@mui/material';
import CctvCamera from '../erebil/Assets/cctv-camera.svg';
import People from '../erebil/Assets/people.svg';
import Lock from '../erebil/Assets/lock.svg';
import Light from '../erebil/Assets/light.svg';
import MonitorCode from '../erebil/Assets/monitor.svg';
import World from '../erebil/Assets/world.svg';
import { useState } from 'react';
import useMainContext from '../components/Context/useMainContext';
import {
	fetchAllSubCategoryByThirdFamily,
	fetchSubCategories,
	fetchArticles,
} from '../Macrosoft_API';

export const Section = () => {
	const {
		setCategories,
		setSubCategories,
		setGroups,
		setArticles,
		setSectionSelected,
		setSearch,
	} = useMainContext();

	const [loading, setLoading] = useState(false);

	async function handleGetGroups(categorie, group) {
		setSectionSelected('ShopNavigator');

		try {
			const params = {
				subFamily: categorie,
				group: group,
				listaPrecio: '1',
				page: '1',
			};
			const response = await fetchAllSubCategoryByThirdFamily(params);

			if (response?.data) {
				setGroups(response.data);
			}
			return response.data;
		} catch (error) {
			console.log('Hubo un error en la obtención ', error);
		}
	}
	const handleGetSubCategories = async (categorie) => {
		try {
			const params = {
				categories: categorie,
				listaPrecio: '1',
				page: '1',
			};
			const response = await fetchSubCategories(params);
			if (response?.data) {
				setSubCategories(response.data);
				return response.data;
			}
		} catch (error) {
			console.log('Hubo un error en la obtención ', error);
		}
	};
	const handleGetArticles = async (subcategorie) => {
		try {
			const params = {
				description: '-',
				CodStock: '-',
				CodCliente: '101',
				family: subcategorie,
				group: '-',
				page: '1',
			};
			const response = await fetchArticles(params);

			if (response) {
				setArticles(response);
				return response;
			}
		} catch (error) {
			console.log('Hubo un error en la obtención ', error);
		}
	};

	const handleGetAllData = async (categorie, subcategorie) => {
		setLoading(true);
		setCategories(subcategorie);
		await handleGetSubCategories(subcategorie);
		await handleGetGroups(subcategorie, categorie);

		handleGetArticles(subcategorie);
		setLoading(false);

		const shopElement = document.getElementById('shopElement');
		shopElement?.scrollIntoView({ behavior: 'smooth' });
	};

	const Contacto = () => {
		setSearch('');
		const footerElement = document.getElementById('footer_target');
		footerElement?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<>
			<Grid
				className='--Section.js---'
				container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					margin: '3rem 0',
					boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
					paddingBottom: '2rem',
				}}
			>
				<Typography
					variant='h1'
					sx={{ color: 'black', margin: '5rem 0', fontSize: '2.5rem' }}
				>
					¿Qué podemos ofrecerle?
				</Typography>
				<Typography
					variant='p'
					sx={{ color: 'black', fontSize: '1.1rem' }}
				>
					Proveemos, múltiples soluciones que se ajustan a cada necesidad y
					presupuesto. Contamos con experiencia certificada, en los Sistemas de
					Seguridad Electrónica, de las marcas más reconocidas a nivel
					internacional, como son: Checkpoint, Pelco, Dahua, Alpha, Se-kure, HID
					y CAS entre otras.
				</Typography>
			</Grid>
			<Grid
				container
				className='items'
				spacing={2}
				sx={{ marginTop: '2rem', marginLeft: '6%' }}
				alignItems='center'
			>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							handleGetAllData('GCN', 20);
						}}
					>
						<img
							src={Lock}
							alt='cctv camera'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid sx={{ marginLeft: '0.7rem' }}>
							<Grid variant='h1'>Chekpoint RF/RFID</Grid>
							<Typography sx={{ '&:hover': { color: 'white' } }}>
								Seguridad Electrónica Anti Hurto
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							handleGetAllData('15.1', 15);
						}}
					>
						<img
							src={CctvCamera}
							alt='cctv camera'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid sx={{ marginLeft: '1rem' }}>
							<Grid variant='h2'>DAHUA</Grid>
							<Typography
								variant='p'
								sx={{ '&:hover': { color: 'white' } }}
							>
								Cctv Video Vigilancia
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							handleGetAllData('TUYA SMART', 17);
						}}
					>
						<img
							src={People}
							alt='people'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid sx={{ marginLeft: '4rem' }}>
							<Grid variant='h2'>Control</Grid>
							<Typography
								variant='p'
								sx={{ fontSize: '1rem', '&:hover': { color: 'white' } }}
							>
								Control de acceso
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							handleGetAllData('Accesorio CCTV', 4);
						}}
					>
						<img
							src={Light}
							alt='cctv camera'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid sx={{ marginLeft: '1.5rem' }}>
							<Grid variant='h2'>Promolux</Grid>
							<Typography
								variant='p'
								sx={{ '&:hover': { color: 'white' } }}
							>
								Iluminación Comercial LED
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							handleGetAllData('Opticon', 19);
						}}
					>
						<img
							src={MonitorCode}
							alt='people'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid sx={{ marginLeft: '1.3rem' }}>
							<Grid variant='h2'>Software/Hardware</Grid>
							<Typography
								variant='p'
								sx={{ '&:hover': { color: 'white' } }}
							>
								Puntos de Venta
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Grid
						container
						alignItems='center'
						className='iconHover'
						sx={{ width: '20rem' }}
						onClick={() => {
							Contacto();
						}}
					>
						<img
							src={World}
							alt='people'
							style={{ width: 40, height: 40, marginRight: '1rem' }}
						/>
						<Grid>
							<Grid variant='h2'>Consultoría</Grid>
							<Typography
								variant='p'
								sx={{ '&:hover': { color: 'white' } }}
							>
								Proyectos de trazabilidad y Logística
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
