import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Box, Button, Grid, TextField} from "@mui/material";
import Modal from "@mui/material/Modal";
import useMainContext from "../Context/useMainContext";
import "../../erebil/Assets/stylesFormRegister.css";
import { useForm } from "react-hook-form";
import { fetchLoginUser, fetchForgetPassword } from "../controller/authController";
import Alert from "@mui/material/Alert";
import ChangePassword from "./ChangePassword";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "40%",
    md: "40%",
  },
  height: {
    xs: "50%",
    sm: "45%",
    md: "45%",
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: 0,
  borderRadius: "10px",
};

export default function LoginModal({ openModalLogin, setopenModalLogin }) {
  const {
    email,
    setEmail,
    setUserName,
    userLogged,
    setUserLogged,
    setRol,
    setTokenJar,
    setCodCliente,
    FirstLogin,
    setFirstLogin,
  } = useMainContext();

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleClearAlert();
    setEmail("");
    setPassword("");
    setopenModalLogin(false);
  };
  const handleForgetPassword = async () => {
    handleClearAlert();
    setPassword("");

    const data = {
      email: email
    };
    
    try {
      const response = await fetchForgetPassword(data);
      if (response) {
        setopenModalChangePassword(true);
      }
    } catch (error) {
      if (error.response) {
        let responseText = error?.response?.data?.message;
        let responseStatus = error?.response?.status;
        if (responseStatus == 401 || 404) {
          setHandleAlert({
            display: true,
            text: responseText,
            metodo: "error",
          });
        }
      } else if (error.request) {
        // La petición fue hecha pero no se recibió respuesta
        console.log("Error de petición:", error.request);
      } else {
        // Algo salió mal al configurar la petición
        console.log("Error:", error.message);
      }
    }
  };
  const [modalAlert, setModalAlert] = useState(false);
  const [text, setText] = useState("");
  const [openModalChangePassword, setopenModalChangePassword] = useState(false);
  const [handleAlert, setHandleAlert] = useState({
    display: false,
    text: "",
    metodo: "",
  });
  const handleClearAlert = () => {
    setHandleAlert({
      display: false,
      text: "",
      metodo: "",
    });
  };
  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await fetchLoginUser(data);
      const token = response.data.token;
      if (!token) {
        throw new Error("No se recibió el token en la respuesta");
      }
      const decoded = jwtDecode(token);
      if (decoded.firstLogin) {
        console.log("primer inicio");
        setopenModalChangePassword(true);
        return
      }
      sessionStorage.setItem("tokenJar", token);
      sessionStorage.setItem("UserLogged", true);
      sessionStorage.setItem("UserRol", true);

      console.log("Información decodificada del token:", decoded);

      setTokenJar(token);
      setUserName(decoded.nombre);
      setEmail(decoded.email);
      setUserLogged(true);
      setCodCliente(decoded.CodCliente);
      setRol(decoded.rol);
      setFirstLogin(decoded.firstLogin);
      handleClose();
      
    } catch (error) {
      if (error.response) {
        let responseText = error?.response?.data?.message;
        let responseStatus = error?.response?.status;
        if (responseStatus == 401 || 404) {
          setHandleAlert({
            display: true,
            text: responseText,
            metodo: "error",
          });
        }
      } else if (error.request) {
        // La petición fue hecha pero no se recibió respuesta
        console.log("Error de petición:", error.request);
      } else {
        // Algo salió mal al configurar la petición
        console.log("Error:", error.message);
      }
    }
  };
  const [typeList, setTypeList] = useState("Seleccione tipo de Usuario");
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOpenModalAlert = () => {
    setText("Se ha enviado un email con la solicitud");
    setModalAlert(true);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={openModalLogin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className="headerFormRegister">
              <h2 className="tituloFormRegister">INICIAR SESION</h2>
            </Grid>
            <Grid className="containerFormRegister">
              <Grid className="containerInputForm">
                <TextField
                  id="campo1"
                  label="Email"
                  value={email}
                  fullWidth
                  margin="normal"
                  {...register("email", {
                    required: "El email es obligatorio",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email inválido",
                    },
                    onChange: (e) => {setEmail(e.target.value)}
                  })
                }
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  onKeyDown={handleEnter}
                />
                <TextField
                  id="campo2"
                  label="Password"
                  type="password"
                  value={password}
                  fullWidth
                  margin="normal"
                  {...register("password", {
                    required: "El password es obligatorio",
                    onChange: (e) => {setPassword(e.target.value)}
                  }
                )}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  onKeyDown={handleEnter}
                />
              </Grid>
            </Grid>
            {handleAlert?.display == true && (
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Alert severity={handleAlert?.metodo}>
                  {handleAlert?.text}
                </Alert>
              </Grid>
            )}

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid sx={{ margin: 2 }}>
                <Button onClick={handleSubmit(handleLogin)} variant="outlined">
                  Iniciar
                </Button>
              </Grid>
              <Grid>
                <Button onClick={handleClose} variant="outlined">
                  Cerrar
                </Button>
              </Grid>
            </Grid>
            <Grid>
                <Button onClick={handleForgetPassword} variant="contained" sx={{marginLeft:{lg:'15%'}}}>
                  Olvide mi Contraseña
                </Button>
              </Grid>
          </Grid>
        </Box>
      </Modal>
      <ChangePassword
        openModalChangePassword={openModalChangePassword}
        setopenModalChangePassword={setopenModalChangePassword}
        userEmail={email}
        actualpass="Contraseña enviada por mail"
      ></ChangePassword>
    </div>
  );
}
