import {
	Grid,
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../erebil/Assets/stylesFormRegister.css';

import fachada from '../components/utils/assets/images/fachada-1024x656.jpg';

export default function AboutUsModal({ aboutUs, setAboutUs }) {
	const FullScreenDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialog-paper': {
			width: '100vw',
			height: '100vh',
			margin: 0, // Elimina cualquier margen para ocupar toda la pantalla
			maxWidth: '100vw',
			maxHeight: '100vh',
		},
	}));

	const handleClose = () => setAboutUs(false);

	return (
		<div>
			<FullScreenDialog
				open={aboutUs}
				onClose={handleClose}
			>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Box
						display='block'
						justifyContent='space-between'
						alignItems='center'
					>
						<Button
							onClick={handleClose}
							variant='contained'
							color='error'
						>
							Cerrar
						</Button>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						className='--CONTAINER-DIALOG--'
						sx={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Grid className='headerFormRegister'>
							<h2 className='tituloFormRegister'>¿Quiénes Somos?</h2>
						</Grid>
						<Grid
							className='--FACHADA-TEXTO-'
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: { xs: 'column', lg: 'row' }, // Column en móvil, row en lg
								flexWrap: 'wrap',
								padding: { lg: '1rem 0' },
								marginBottom: { lg: '1%', md: '5%', xs: '5%' },
							}}
						>
							<Box
								className='--FACHADA--'
								component='img'
								src={fachada}
								alt='fachada'
								sx={{
									maxHeight: { lg: '80%', xs: '100%' },
									maxWidth: { lg: '40%', xs: '100%' },
									marginTop: { lg: '1%', xs: '5%' },
									borderRadius: '10px',
								}}
							/>

							<Box // Cambié div por Box para usar sx prop
								className='--TEXTO--'
								sx={{
									fontSize: '1.2rem',
									margin: '1% 0 0 0',
									maxWidth: { lg: '55%', xs: '100%' }, // Ajusté los anchos
								}}
							>
								Erebil S.A. es una compañía 100% uruguaya con más de 30 años en
								el mercado, enfocada en brindar soluciones de calidad, apostando
								a la innovación tecnológica, profesionalismo y satisfacción de
								nuestros clientes. Integradora de servicios tecnológicos que
								suministra asesoría, diseño, mantenimiento y comercialización de
								equipos de seguridad electrónica, CCTV, automatismos, audio,
								etc.
							</Box>
						</Grid>

						<Grid
							justifyContent='center'
							alignItems='center'
							sx={{
								textAlign: 'center',
								marginTop: { lg: '1%', md: '0%', xs: '5%' },
								display: { lg: 'flex', md: 'flex', xs: 'block' },
								flexDirection: { xs: 'column', lg: 'row' },
								gap: { lg: '2rem', md: '1rem', xs: '1rem' }, // Espaciado en desktop y mobile
							}}
						>
							{/* MISIÓN */}
							<Grid
								container
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', md: 'row' }, // Column en móvil, row en md y arriba
									gap: '1rem', // Gap entre los elementos
									alignItems: 'stretch',
									justifyContent: 'space-between',
								}}
							>
								<Grid
									className='--MISIÓN--'
									item
									xs={12}
									md={5.8} // Ajustado para dejar espacio para el gap
									sx={{
										background: '#727272',
										padding: '1rem',
										borderRadius: '10px',
										color: 'whitesmoke',
										display: 'flex',
										flexDirection: 'column',
										height: '100%',
									}}
								>
									<Typography
										variant='h5'
										sx={{ fontWeight: 500, mb: 2 }}
									>
										MISIÓN
									</Typography>
									<Typography>
										Somos una compañía fundada en el año 1989, con una amplia
										trayectoria en plaza, que suministra servicios de asesoría,
										mantenimiento y comercialización de soluciones integrales en
										seguridad y control electrónico. Nuestra oferta de productos
										de calidad garantizada y nuestro servicio se fundamentan en
										los valores de la compañía, de manera que logremos la
										satisfacción y expectativas de nuestros clientes.
									</Typography>
								</Grid>

								<Grid
									className='--VISION--'
									item
									xs={12}
									md={5.8} // Ajustado para dejar espacio para el gap
									sx={{
										background: '#727272',
										padding: '1rem',
										borderRadius: '10px',
										color: 'whitesmoke',
										display: 'flex',
										flexDirection: 'column',
										height: '100%',
									}}
								>
									<Typography
										variant='h5'
										sx={{ fontWeight: 500, mb: 2 }}
									>
										VISIÓN
									</Typography>
									<Typography>
										Buscamos ser reconocidos como una de las organizaciones
										líderes a nivel nacional por brindar Soluciones Integradas
										en Seguridad y Control Electrónico de calidad. Fortalecemos
										la capacidad de nuestros procesos, basados en estándares
										internacionales que encaminen a la compañía hacia el
										mejoramiento continuo y la innovación en el servicio,
										manteniendo una evolución acorde a las oportunidades del
										mercado.
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						{/* '--VALORES--' */}
						<Grid
							className='--VALORES--'
							sx={{
								marginTop: { lg: '2%' },
							}}
						>
							<h3 style={{ marginLeft: { lg: '5%' } }}>
								<strong>VALORES</strong>
							</h3>

							<List sx={{ padding: '0.5rem' }}>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Lealtad{' '}
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Comportamiento Ético{' '}
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Respeto{' '}
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Profesionalismo{' '}
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Desarrollo del Capital Humano
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 'bold' }}>
												{' '}
												Responsabilidad Social Empresarial
											</Typography>
										}
										sx={{ fontWeight: 'bold' }}
									/>
								</ListItem>
							</List>
						</Grid>
						{/* '--FUNDADORES--' */}
						<Grid
							className='--FUNDADORES--'
							sx={{
								maxWidth: { lg: '100%', md: '100%', xs: '100%' }, // Controla el ancho
								// mx: 'auto', // Centra el contenido
								marginTop: { lg: '5%', md: '5%', xs: '5%' }, // Ajusta la separación superior
								marginLeft: { lg: '0%', md: '0%', xs: '0%' },
								px: { lg: '0rem', md: '1rem', xs: '0rem' }, // Padding lateral para mejor alineación
							}}
						>
							<h3 style={{ marginLeft: { lg: '5%' } }}>
								<strong>SOMOS MIEMBROS FUNDADORES DE C.U.T.I.</strong>
							</h3>

							<Grid
								className='--FUNDADORES-ITEMS-'
								sx={{
									px: { lg: '1rem', md: '1rem', xs: '0rem' },
								}}
							>
								<Typography paragraph>
									Firma pionera en la Automatización Informática de Empresas,
									contamos con todos los elementos necesarios para:
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									Puntos de Venta (POS) y su administración.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									Sistemas de Seguridad (EAS, RF, CCTV digital y Remoto, Acceda
									Control de accesos y presentismo, relojes de personal
									Seguridad y RFID.)
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									RFID. Consultoría local e internacional (MEXIKEY)
									Instalaciones.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									DOMÓTICA - Estudio de Proyectos.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									PROMOLUX/ECONOFROST. Control de Alimentos Perecederos.
									Lámparas de muy bajo nivel de rayos ultravioletas. Cortinas de
									protección de góndolas de frío.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									Etiquetas RR.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									Taggy - Display de precios digitales RF.
								</Typography>

								<Typography
									sx={{ textDecoration: 'underline' }}
									paragraph
								>
									Hardware e insumos de informática para todas las necesidades.
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</FullScreenDialog>
		</div>
	);
}
