import { createContext, useState, useEffect } from "react";
import {
  fetchSubCategories,
  fetchAllSubCategoryByThirdFamily,
  fetchArticles
} from "../../Macrosoft_API";
// import useMainContext from "./useMainContext";
export const Context = createContext();

const Provider = ({ children }) => {
  const [subCategories, setSubCategories] = useState([]);
  const testContext = "Context Exitoso desde Categories";
  const handleGetSubCategories = async (categorie) => {
    try {
      const params = {
        categories: categorie,
        listaPrecio: "1",
        page: "1",
      };
      const response = await fetchSubCategories(params);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const handleGetGroups = async (categorie, group) => {
    // setSectionSelected("ShopNavigator");
    // console.log('funciona el icon');
    // console.log('subFamily: ', categorie);
    // console.log('group: ', group);
    try {
      const params = {
        subFamily: categorie,
        group: group,
        listaPrecio: "1",
        page: "1",
      };
      const response = await fetchAllSubCategoryByThirdFamily(params);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
	const handleGetArticles = async (subcategorie,CodCliente) => {
		console.log('subcategorie ', subcategorie);
		console.log('CodCliente ', CodCliente);
		try {
			const params = {
				description: '-',
				CodStock: '-',
				CodCliente: CodCliente?`'${CodCliente}'`:'101',
				family: subcategorie,
				group: '-',
				page: '1',
			};
			const response = await fetchArticles(params);

			// console.log('respuesta de obtener los articulos:', response);
			if (response) {
				
				return response;
			}
		} catch (error) {
			// console.log('hubo un error en el fetch:', error);
		}
	};
  const value = {
    testContext,
    handleGetSubCategories,
    handleGetGroups,
    subCategories,
    setSubCategories,
	handleGetArticles
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Provider };
