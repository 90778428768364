import * as React from 'react';
import { useState, useEffect } from 'react';
import {
	Grid,
	TextField,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	Button,
	Badge,
	CircularProgress,
	Box,
	Typography,
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './Assets/logo.png';
import perfilIcon from './Assets/perfil.png';
import carritoIcon from './Assets/carrito-de-compras.png';
import RegisterModal from '../components/modalRegister';
import AboutUsModal from '../components/AboutUs';
import LoginModal from '../components/Login/modalLogin';
import PreOrderModal from '../components/utils/PreOrderModal';
import useMainContext from '../components/Context/useMainContext';
import { fetchArticlesByFilter } from '../Macrosoft_API/index';
import MenuAdminContainer from '../components/AdminPanel/MenuAdminContainer';
import '../components/utils/assets/headerPrincipal.css';
import ChangePassword from '../components/Login/ChangePassword';

const theme = createTheme({
	palette: {
		primary: {
			main: '#E21B1B', // Define tu color principal aquí
		},
	},
});
export default function HeaderPrincipal() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isInitialized, setIsInitialized] = useState(false); // Nuevo estado para inicialización

	useEffect(() => {
		setIsInitialized(true); // Marca que la inicialización está completa
	}, []);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const [openModalRegister, setOpenModalRegister] = useState(false);
	const [openModalLogin, setOpenModalLogin] = useState(false);
	const [openModalPreOrder, setOpenModalPreOrder] = useState(false);
	const [aboutUs, setAboutUs] = useState(false);
	const [openModalChangePassword, setopenModalChangePassword] = useState(false);

	const [loading, setLoading] = useState(false);

	/* context */
	const {
		email,
		userName,
		setShowMainCarrousel,
		setArticles,
		userLogged,
		setUserLogged,
		setUserName,
		arrayProductShop,
		setRol,
		rol,
		search,
		setSearch,
		setSectionSelected,
		setCodCliente,
	} = useMainContext();
	const handleSearch = async () => {
		setLoading(true);
		setSectionSelected('ShopNavigator');
		try {
			const response = await fetchArticlesByFilter({ search });
			console.log('Respuesta de obtener artículos por búsqueda:', response);
			setArticles(response);
			setShowMainCarrousel(false);
		} catch (error) {
			console.log('Hubo un error', error);
		} finally {
			setLoading(false);
		}
	};

	const handleLogOut = () => {
		setRol(null);
		setUserLogged(false);
		setUserName('');
		setCodCliente(false);
		LogoClick();
		sessionStorage.setItem('UserLogged', false);
		sessionStorage.clear();
	};

	function LogoClick() {
		setSectionSelected('Carrousel');
		setSearch('');
	}

	useEffect(() => {
		if (search.trim() !== '') {
			handleSearch();
		}
	}, [search]);

	// Renderiza un loader mientras se inicializan los estados
	if (!isInitialized) {
		return (
			<Box
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					bgcolor: 'rgba(255, 255, 255, 1)',
					zIndex: 1300,
				}}
			>
				<CircularProgress color='primary' />
			</Box>
		);
	}

	const handleAboutUs = () => {
		setAboutUs(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				sx={{ display: 'flex', justifyContent: 'center', marginLeft: '5%' }}
				id='header_target'
			>
				{/* Logo */}
				<Grid
					item
					xs={6}
					sm={6}
					md={2}
				>
					<img
						style={{ cursor: 'pointer' }}
						onClick={() => {
							LogoClick();
						}}
						width={'80%'}
						src={logo}
						alt={'Erebil'}
						loading='lazy'
						height={'150px'}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={7}
					sx={{ marginRight: '17%' }}
				>
					<TextField
						sx={{ width: '80%', marginTop: '2rem' }}
						label='Buscador..'
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					sx={{
						display: { xs: 'none', sm: 'flex' },
						justifyContent: 'space-between',
						marginTop: { lg: '-2%' },
						marginLeft: { lg: '-20%' },
					}}
				>
					<Stack
						direction='row'
						spacing={2}
						alignItems='center'
					>
						<Grid
							item
							xs={12}
							sm={6}
							md={7}
							lg={1}
							sx={{ width: '100%', paddingRight: { lg: '25%' } }}
						>
							<Button
								id='about_us_link'
								onClick={handleAboutUs}
							>
								¿Quiénes Somos?
							</Button>
						</Grid>
						<Badge
							badgeContent={arrayProductShop?.length}
							color='error'
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<IconButton
								onClick={() => {
									if (arrayProductShop.length > 0) {
										setOpenModalPreOrder(true);
									}
								}}
							>
								<img
									src={carritoIcon}
									alt='Carrito'
									style={{ width: '3rem', height: '3rem' }}
								/>
							</IconButton>
						</Badge>

						<div>
							<IconButton
								sx={{
									width: '4rem', // Ajusta el tamaño total del botón
									height: '4rem',
								}}
								onClick={handleMenuClick}
							>
								<img
									src={perfilIcon}
									alt='Perfil'
									style={{
										width: '3rem',
										height: '3rem',
									}}
								/>
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
							>
								{!userLogged && (
									<MenuItem
										onClick={() => {
											setOpenModalLogin(true);
											handleMenuClose();
										}}
									>
										Iniciar Sesión
									</MenuItem>
								)}
								{userLogged && (
									<>
										<MenuItem>{userName}</MenuItem>
										<MenuItem
											onClick={() => {
												setopenModalChangePassword(true);
												handleMenuClose();
											}}
										>
											Cambiar mi Contraseña
										</MenuItem>
										<MenuItem
											onClick={() => {
												handleLogOut();
												handleMenuClose();
											}}
										>
											Cerrar Sesión
										</MenuItem>
									</>
								)}
							</Menu>
						</div>

						{!userLogged && (
							<Button
								variant='contained'
								onClick={() => setOpenModalRegister(true)}
								sx={{ width: '33%' }} // Clase CSS para animación
							>
								REGISTRARME
							</Button>
						)}

						{userLogged && rol === 'Administrador' && (
							<Grid
								sx={{
									display: 'flex',
									alignContent: 'center',
									alignItems: 'center',
								}}
							>
								<MenuAdminContainer />
								<Typography>Bienvenido Administrador</Typography>
							</Grid>
						)}
					</Stack>
				</Grid>
			</Grid>
			<RegisterModal
				openModalRegister={openModalRegister}
				setopenModalRegister={setOpenModalRegister}
			/>
			<LoginModal
				openModalLogin={openModalLogin}
				setopenModalLogin={setOpenModalLogin}
			/>
			<ChangePassword openModalChangePassword={openModalChangePassword} setopenModalChangePassword={setopenModalChangePassword} actualpass="Contraseña Actual"></ChangePassword>
			<PreOrderModal
				open={openModalPreOrder}
				handleClose={() => {
					setOpenModalPreOrder(false);
				}}
			/>
			<AboutUsModal
				aboutUs={aboutUs}
				setAboutUs={setAboutUs}
			></AboutUsModal>
			{loading && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						bgcolor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 1300,
					}}
				>
					<CircularProgress color='primary' />
				</Box>
			)}
		</ThemeProvider>
	);
}
