import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Collapse,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useCategoriesContext from "../components/Context/useCategoriesContext";
import useMainContext from "../components/Context/useMainContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fetchAllFamilies, fetchArticles } from "../Macrosoft_API";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";

function MenuLatMobile() {
  const {
    handleGetSubCategories,
    subCategories,
    setSubCategories,
    handleGetArticles,
  } = useCategoriesContext();
  const { CodCliente, setSectionSelected,setArticles } = useMainContext();
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleGetCategories = async () => {
    setLoading(true);
    try {
      const response = await fetchAllFamilies();
      setCategories(response.data);
    } catch (error) {
      console.log("Error en el fetch: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSetArticles = async (subcategorie) => {
    setLoading(true);
    setSectionSelected("ShopNavigator");
    toggleDrawer();
	console.log('subCategorie: ',subcategorie)
    try {
      const params = {
        description: subcategorie.Descripcion,
        CodStock: "-",
        CodCliente: CodCliente? `${CodCliente}`:'101',
        family: '-',
        group: subcategorie.Grupo,
        page: "1",
      };
      const response = await fetchArticles(params);
console.log('response: ',response)
      if (response) {
        setArticles(response);

      }
    } catch (error) {
      console.log("Hubo un error en la obtención ", error);
    }
  };
  const handleClick = async (category) => {
    const responseSubCategorie = await handleGetSubCategories(category.SupFlia);
    setSubCategories(responseSubCategorie);

    // Si ya está abierta, la cierra; si es una nueva, la abre
    setOpenCategory((prev) =>
      prev === category.SupFlia ? null : category.SupFlia
    );
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80%",
            boxSizing: "border-box",
          },
        }}
      >
        <Box>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#E21B1B",
              padding: 1,
            }}
          >
            <Typography sx={{ color: "white" }} variant="h4">
              Categorías
            </Typography>
            <ArrowBackIcon
              sx={{ cursor: "pointer", color: "white" }}
              onClick={toggleDrawer}
            />
          </Grid>

          <List>
            {categories?.map((category) => (
              <div key={category.SupFlia}>
                <ListItem button onClick={() => handleClick(category)}>
                  <ListItemIcon>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={category.Descripcion} />
                  {openCategory === category.SupFlia ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>

                {/* Subcategorías dentro de Collapse */}
                <Collapse
                  in={openCategory === category.SupFlia}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {subCategories?.map((sub) => (
                      <ListItem
                        key={sub.Grupo}
                        onClick={() => {
                          handleSetArticles(sub);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary={sub.Descripcion} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default MenuLatMobile;
