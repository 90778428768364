import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Box, Button, Grid, TextField, Snackbar } from "@mui/material";
import Modal from "@mui/material/Modal";
import useMainContext from "../Context/useMainContext";
import "../../erebil/Assets/stylesFormRegister.css";
import { useForm } from "react-hook-form";
import { fetchChangePassword } from "../controller/authController";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "40%",
    md: "40%",
  },
  height: {
    xs: "50%",
    sm: "45%",
    md: "45%",
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: 0,
  borderRadius: "10px",
};

export default function ChangePassword({
  openModalChangePassword,
  setopenModalChangePassword,
  actualpass
}) {
  const {
    email,
		setEmail,
    setUserName,
    userLogged,
    setUserLogged,
    setRol,
    tokenJar,
    setTokenJar,
    setCodCliente,
    FirstLogin,
    setFirstLogin,
    setSearch,
  } = useMainContext();

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleClearAlert();
    setConfirmEmail("");
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setOpen(false);
    setopenModalChangePassword(false);
  };
  const [modalAlert, setModalAlert] = useState(false);
  const [text, setText] = useState("");
  const [handleAlert, setHandleAlert] = useState({
    display: false,
    text: "",
    metodo: "",
  });
  const handleClearAlert = () => {
    setHandleAlert({
      display: false,
      text: "",
      metodo: "",
    });
  };
  const handleChangePassword = async () => {

    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      email: confirmEmail
    };

    console.log("data que paso", data);

    try {
      console.log("CHANGE ANTES: ", data);
      const response = await fetchChangePassword(data);
      console.log("CHANGE DESPUES: ", data);
      setSuccessMessage(response.data.message); // Mensaje de éxito desde el backend
      setErrorMessage(""); // Limpiar errores previos
      setOpen(true); // Mostrar la alerta
      const token = response.data.token;
      if (!token) {
        throw new Error("No se recibió el token en la respuesta");
      }
      sessionStorage.setItem("tokenJar", token);
      sessionStorage.setItem("UserLogged", true);
      sessionStorage.setItem("UserRol", true);
      const decoded = jwtDecode(token);

      console.log("Información decodificada del token:", decoded);

      setTokenJar(token);
      setUserName(decoded.nombre);
      setUserLogged(true);
      setCodCliente(decoded.CodCliente);
      setRol(decoded.rol);
      setFirstLogin(false);
      handleClose()
    } catch (error) {
      if (error.response) {
        let responseText = error?.response?.data?.message;
        let responseStatus = error?.response?.status;
        if (responseStatus == 401 || 404) {
          setHandleAlert({
            display: true,
            text: responseText,
            metodo: "error",
          });
        }
      } else if (error.request) {
        // La petición fue hecha pero no se recibió respuesta
        console.log("Error de petición:", error.request);
      } else {
        // Algo salió mal al configurar la petición
        console.log("Error:", error.message);
      }
    }
  };

  const [typeList, setTypeList] = useState("Seleccione tipo de Usuario");
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleChangePassword();
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOpenModalAlert = () => {
    setText("Contraseña actualizada con éxito");
    setModalAlert(true);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={openModalChangePassword}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className="headerFormRegister">
              <h2 className="tituloFormRegister">Cambiar mi Contraseña</h2>
            </Grid>
            <Grid className="containerFormRegister">
              <Grid className="containerInputForm">
              <TextField
                  id="campo6"
                  label="Confirmar email"
                  type="email"
                  value={confirmEmail}
                  fullWidth
                  margin="normal"
                  {...register("email", {
                    required: "La confirmación de email es obligatoria",
                    onChange: (e) => {setConfirmEmail(e.target.value)}
                  })}
                  error={!!errors.confirmEmail}
                  helperText={errors.confirmEmail?.message}
                  onKeyDown={handleEnter}
                />
                <TextField
                  id="campo3"
                  label={actualpass}
                  type="password"
                  value={oldPassword}
                  fullWidth
                  margin="normal"
                  {...register("oldPassword", {
                    required: "La vieja contraseña es obligatoria",
                    onChange: (e) => {setOldPassword(e.target.value)}
                  })}
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                  onKeyDown={handleEnter}
                />

                <TextField
                  id="campo4"
                  label="Nueva Contraseña"
                  type="password"
                  value={newPassword}
                  fullWidth
                  margin="normal"
                  {...register("newPassword", {
                    required: "La nueva contraseña es obligatoria",
                    onChange: (e) => {setNewPassword(e.target.value)}
                  })}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  onKeyDown={handleEnter}
                />
                <TextField
                  id="campo5"
                  label="Confirmar Nueva Contraseña"
                  type="password"
                  value={confirmPassword}
                  fullWidth
                  margin="normal"
                  {...register("confirmPassword", {
                    required: "Confirmar la contraseña es obligatorio",
                    onChange: (e) => {setConfirmPassword(e.target.value)}
                  })}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  onKeyDown={handleEnter}
                />
              </Grid>
            </Grid>
            {/* Snackbar para éxito */}
            <Snackbar
              open={open && successMessage !== ""}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{
                  width: "100%",
                  marginRight: { lg: "-1%" },
                  marginTop: { lg: "50%" },
                  marginLeft: { lg: "120%" },
                  paddingLeft: { lg: "15%" },
                }}
              >
                {successMessage}
              </Alert>
            </Snackbar>

            {/* Snackbar para errores */}
            <Snackbar
              open={open && errorMessage !== ""}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: "100%",
                  marginRight: { lg: "-1%" },
                  marginTop: { lg: "50%" },
                  marginLeft: { lg: "120%" },
                  paddingLeft: { lg: "15%" },
                }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid sx={{ margin: 2 }}>
                <Button
                  onClick={handleSubmit(handleChangePassword)}
                  variant="outlined"
                >
                  Confirmar
                </Button>
              </Grid>
              <Grid>
                <Button onClick={handleClose} variant="outlined">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
