import { createContext, useState, useEffect } from 'react';

export const Context = createContext();

const Provider = ({ children }) => {
	const testContext = 'Context Exitoso';
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [groups, setGroups] = useState([]);
	const [articles, setArticles] = useState([]);
	const [sectionSelected, setSectionSelected] = useState('Carrousel');
	const [arrayProductShop, setArrayProductShop] = useState([]);
	const [primaryColor, setPrimaryColor] = useState('E21B1B');
	const [search, setSearch	] = useState('');
	const [email, setEmail] = useState('');
	const [userName, setUserName] = useState('');
	const [userLogged, setUserLogged] = useState(false); // Estado inicial en falso
	const [rol, setRol] = useState('');
	const [tokenJar, setTokenJar] = useState('');
	const [CodCliente, setCodCliente] = useState(false)
	const [FirstLogin, setFirstLogin] = useState(false)
	// Verifica el estado de userLogged en sessionStorage solo si existe
	useEffect(() => {
		const storedUserLogged = sessionStorage.getItem('UserLogged');
		if (storedUserLogged === 'true') {
			setUserLogged(true); // Solo cambia si el valor en sessionStorage es true
		}
	}, []);

	// Guarda cualquier cambio de userLogged en sessionStorage
	useEffect(() => {
		sessionStorage.setItem('UserLogged', userLogged);
	}, [userLogged]);
	useEffect(() => {
		console.log('CODIGO CLIENTE: ', CodCliente)
	}, [CodCliente]);
	// Verifica el estado de rol en sessionStorage solo si existe
	useEffect(() => {
		const storedUserRol = sessionStorage.getItem('UserRol');
		if (storedUserRol) {
			setRol(storedUserRol); // Actualiza el estado de rol si hay un valor en sessionStorage
		}
	}, []);

	// Guarda cualquier cambio de rol en sessionStorage
	useEffect(() => {
		sessionStorage.setItem('UserRol', rol);
	}, [rol]);

	// Verifica el nombre en sessionStorage solo si existe
	useEffect(() => {
		const storedUserName = sessionStorage.getItem('UserName');
		if (storedUserName) {
			setUserName(storedUserName); // Actualiza el estado de rol si hay un valor en sessionStorage
		}
	}, []);

	const value = {
		testContext,
		categories,
		setCategories,
		subCategories,
		setSubCategories,
		groups,
		setGroups,
		articles,
		setArticles,
		sectionSelected,
		setSectionSelected,
		primaryColor,
		setPrimaryColor,
		arrayProductShop,
		setArrayProductShop,
		search,
		setSearch,
		email,
		setEmail,
		userName,
		setUserName,
		userLogged,
		setUserLogged,
		rol,
		setRol,
		tokenJar,
		setTokenJar,
		CodCliente,
		setCodCliente,
		FirstLogin,
		setFirstLogin
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Provider };
