import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	Grid,
	IconButton,
	Modal,
	TableContainer,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	Table,
	TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useMainContext from '../Context/useMainContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchSendPurchase } from '../controller/mailController';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import '../../erebil/Assets/stylesFormRegister.css';
import ModalAlert from './modalAlert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '90%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '15px',
	display: 'flex',
	padding: 0,
	flexDirection: 'column',
	// borderTop: '15px solid #E21B1B'
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#E21B1B',
		},
		success: {
			main: '#5AB142',
		},
	},
});

const contentStyle = {
	flex: '1 1 auto',
	overflowY: 'auto',
	mb: 3,
	padding: 0,
};

const footerStyle = {
	position: 'sticky',
	display: 'flex',
	justifyContent: 'center',
	bottom: 0,
	backgroundColor: 'background.paper',
	padding: '10px',
	boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
};

const PreOrderModal = ({ open, handleClose }) => {
	const {
		arrayProductShop,
		setArrayProductShop,
		mailContext,
		loguedUser,
		profileContext,
	} = useMainContext();
	const [arrayProductLocal, setArrayProductLocal] = useState([]);
	const [color, setColor] = useState('');
	const [message, setMessage] = useState('');
	const [openModalAvisos, setOpenModalAvisos] = useState(false);
	const [loading, setLoading] = useState(false);
	const [shippingCost, setShippingCost] = useState(0);
	const [form, setForm] = useState(false);
	const [modalAlert, setModalAlert] = useState(false)
	const [text, setText] = useState('')
	const [cant, setCant] = useState(0);

	// Modificar el handleClose original para que actualice arrayProductShop
	const handleCloseAndUpdate = () => {
		setArrayProductShop(arrayProductLocal); // Actualiza el context con las nuevas cantidades
		handleClose();
	};

	const handleRemoveProduct = (productId) => {
		const updatedArray = arrayProductShop.filter(
			(product) => product.CodArticulo !== productId
		);
		setArrayProductShop(updatedArray);
	};

	const getTotal = () => {
		const productTotal = arrayProductLocal?.reduce((total, product) => {
			return total + product.precio * (product.quantity || 1);
		}, 0);
		return productTotal.toFixed(2);
	};

	const handleAlert = (message) => {
		setModalAlert(true);
		setText(message)
	};

	const sendPurchase = async () => {
		// console.log('profile', profileContext);
		// const Profile = {
		// 	firstName: profileContext.name,
		// 	lastName: profileContext.lastName,
		// 	address: `${profileContext.mainStreet} ${profileContext.cournerStreet} ${profileContext.doorNumber}`,
		// 	phone: profileContext.phone,
		// 	email: mailContext,
		// };
		const Profile = {
			firstName: 'Javier',
			lastName: 'Romero',
			address: ` Direccion 4190`,
			phone: '092130512',
			email: 'javierromero764@gmail.com',
		};

		// setFormData(formDataFromProfile);

		const data = {
			to: 'jromeroprogramador14@gmail.com',
			products: arrayProductLocal,
			subject:'Solicitud de Pedido',
			shippingCost,
			option: 'templateResumenCompra',
			totalCost: getTotal(),
			profile: Profile,
		};
		console.log('data antes de enviar', data);
		try {
			setLoading(true);
			const response = await fetchSendPurchase(data);
			handleAlert('Se ha enviado un mail con la confirmacion del pedido');
			setArrayProductShop([]);
			resetForm();
			setForm(false);
		} catch (error) {
			console.log('hubo un error');
			handleAlert('hubo un error', 'warning');
		} finally {
			setLoading(false);
		}

		// else {
		// 	setForm(true);
		// 	if (formData.email !== '') {
		// 		console.log('Se envian los datos', formData);
		// 		const data = {
		// 			to: formData.email,
		// 			products: arrayProductLocal,
		// 			shippingCost,
		// 			totalCost: getTotal(),
		// 			formData,
		// 		};
		// 		try {
		// 			setLoading(true);
		// 			// const response = await fetchSendMail(data);
		// 			// console.log('responseMail', response);
		// 			handleAlert(
		// 				'Se ha enviado un mail con la confirmacion del pedido',
		// 				'primary'
		// 			);
		// 			setArrayProductShop([]);
		// 			resetForm();
		// 			setForm(false);
		// 		} catch (error) {
		// 			console.log('hubo un error', error);
		// 			handleAlert('hubo un error', 'warning');
		// 		} finally {
		// 			setLoading(false);
		// 		}
		// 	}
		// 	console.log('no hay usuario logueado');
		// }
	};
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		address: '',
		phone: '',
		email: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const resetForm = () => {
		setFormData({
			firstName: '',
			lastName: '',
			address: '',
			phone: '',
			email: '',
		});
	};
	const handleQuantityChange = (productId, newQuantity) => {
		setArrayProductLocal((prevArray) =>
			prevArray?.map((product) =>
				product.CodArticulo === productId
					? { ...product, quantity: newQuantity }
					: product
			)
		);
	};
	useEffect(() => {
		if (open) {
			console.log('Se abre modal de pre compra: ', profileContext);
		}
	}, [open]);

	useEffect(() => {
		const newArrayProduct = arrayProductShop?.map((product) => ({
			...product,
			quantity: 1,
		}));
		console.log('nuevo array', newArrayProduct);
		setArrayProductLocal(newArrayProduct);
	}, [arrayProductShop]);

	useEffect(() => {
		const total = getTotal();
		if (arrayProductLocal?.length < 1) {
			handleClose();
		}
		if (total > 1500) {
			console.log('ingreso al if');
			setShippingCost(0);
		} else {
			// Optional: reset shipping cost if total is below or equal to 1500
			setShippingCost(100);
		}
		console.log('array poroduct local: ', arrayProductLocal);
		// setArrayProductShop(arrayProductLocal)
	}, [arrayProductLocal]);

	useEffect(() => {
		if (open) {
			const newArrayProduct = arrayProductShop?.map((product) => ({
				...product,
				quantity: product.quantity || 1,
			}));
			setArrayProductLocal(newArrayProduct);
		}
	}, [open, arrayProductShop]);

	const updateQuantity = (productId, newQuantity) => {
		if (newQuantity < 1) {
			handleRemoveProduct(productId);
		}
		setArrayProductLocal(
			arrayProductLocal.map((item) =>
				item.CodArticulo === productId
					? { ...item, quantity: newQuantity }
					: item
			)
		);
	};

	return (
		<ThemeProvider theme={theme}>
			<Modal
				open={open}
				onClose={handleCloseAndUpdate}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Card sx={style}>
					<CardContent sx={contentStyle}>
						{form ? (
							<Grid>
								{/* CONTENIDO DE FORMULARIO SIN REGISTRO */}
								<Grid
									container
									justifyContent='space-between'
									alignItems='center'
								>
									<Typography
										variant='h4'
										component='div'
										gutterBottom
									>
										COMPLETE FORMULARIO DE CONTACTO
									</Typography>
									<IconButton
										onClick={() => {
											setForm(false);
										}}
									>
										<ArrowBackIcon />
									</IconButton>
								</Grid>
								<Grid
									container
									spacing={2}
									marginTop={5}
								>
									<Grid
										item
										xs={12}
										sm={6}
									>
										<TextField
											label='Nombre'
											name='firstName'
											value={formData.firstName}
											onChange={handleChange}
											fullWidth
											required
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
									>
										<TextField
											label='Apellido'
											name='lastName'
											value={formData.lastName}
											onChange={handleChange}
											fullWidth
											required
										/>
									</Grid>
									<Grid
										item
										xs={12}
									>
										<TextField
											label='Dirección'
											name='address'
											value={formData.address}
											onChange={handleChange}
											fullWidth
											required
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
									>
										<TextField
											label='Teléfono'
											name='phone'
											value={formData.phone}
											onChange={handleChange}
											fullWidth
											required
											type='tel'
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
									>
										<TextField
											label='Correo Electrónico'
											name='email'
											value={formData.email}
											onChange={handleChange}
											fullWidth
											required
											type='email'
										/>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid>
								{/* CONTENIDO DE PRE COMPRA */}
								<Grid
									sx={{
										width: '100%',
										backgroundColor: '#E21B1B',
										padding: '13px',
									}}
									container
									justifyContent='space-between'
									alignItems='center'
								>
									<Typography
										sx={{ color: 'white' }}
										variant='h4'
										component='div'
										gutterBottom
									>
										Resumen de Compra
									</Typography>
									<IconButton onClick={handleCloseAndUpdate}>
										<CloseIcon />
									</IconButton>
								</Grid>
								<Grid
									sx={{ marginTop: '5%', paddingX: '5%' }}
									container
									spacing={2}
								>
									<TableContainer sx={{ overflowX: 'auto' }}>
										<Table
											sx={{ minWidth: { xs: '100%', sm: 650 } }}
											aria-label='simple table'
										>
											<TableHead>
												<TableRow>
													<TableCell>Codigo Articulo</TableCell>
													<TableCell>Descripcion</TableCell>
													<TableCell align='right'>Cantidad</TableCell>
													<TableCell align='right'>Precio</TableCell>
													<TableCell
														align='right'
														sx={{ display: { xs: 'none', sm: 'table-cell' } }}
													>
														Gestion
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{arrayProductLocal?.map((row) => (
													<TableRow
														key={row.CodArticulo}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														<TableCell
															component='th'
															scope='row'
														>
															{row.CodArticulo}
														</TableCell>
														<TableCell
															component='th'
															scope='row'
														>
															{row.descripcion}
														</TableCell>
														<TableCell
															align='right'
															sx={{ width: '20%' }}
														>
															{/* -------------------------------------------------------- */}
															<TextField
																type='number'
																value={row.quantity || cant}
																onChange={(e) => {
																	updateQuantity(
																		row.CodArticulo,
																		Number(e.target.value)
																	);
																}}
																sx={{
																	maxHeight: '50px',
																	backgroundColor: 'white',
																	width: '60px',
																	marginLeft: '5%',
																}}
																inputProps={{
																	min: 0,
																	max: 999,
																	step: 1,
																	sx: {
																		paddingRight: '6px',
																		paddingLeft: '10px',
																	},
																}}
															/>
														</TableCell>
														<TableCell align='right'>U$S{row.precio}</TableCell>
														<TableCell align='right'>
															<IconButton
																onClick={() =>
																	handleRemoveProduct(row.CodArticulo)
																}
																color='error'
															>
																<DeleteIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid sx={{ paddingX: '5%' }}>
									<Grid
										item
										xs={15}
										sm={15}
										md={10}
										lg={10}
									>
										<Grid
											container
											alignItems='center'
										></Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
					</CardContent>
					<Box
						sx={{
							backgroundColor: 'background.paper',
							boxShadow: 3,
							p: 2,
							marginX: '5%',
							marginBottom: '2%',
							paddingX: '10%',
						}}
						display='flex'
						justifyContent='space-between'
						mt={3}
					>
						<Typography variant='h6'>Total:</Typography>
						<Typography variant='h6'>{`U$S${getTotal()}`}</Typography>
					</Box>
					<Box sx={footerStyle}>
						<Button
							onClick={sendPurchase}
							variant='contained'
							color='primary'
							// fullWidth
							disabled={loading}
						>
							Confirmar Compra
						</Button>
					</Box>
					{loading && (
						<Box
							sx={{
								position: 'fixed',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								bgcolor: 'rgba(0, 0, 0, 0.5)',
								zIndex: 1300,
							}}
						>
							<CircularProgress />
						</Box>
					)}
				</Card>
			</Modal>
			<ModalAlert
				open={modalAlert}
				handleClose={() => {
					setModalAlert(false)
					handleClose()
				}
				}
				text={text}
			/>
		</ThemeProvider>
	);
};

export default PreOrderModal;
