import { useState, useEffect } from "react";
import useMainContext from "./Context/useMainContext";
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Modal,
  Alert,
  Typography,
} from "@mui/material";
import "../erebil/Assets/stylesFormRegister.css";
import {
  fetchCreateUser,
  fetchRequestRegister,
  fetchUpdateUser,
} from "./controller/userController";
import ModalAlert from "./utils/modalAlert";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  height: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
  padding: 0,
  borderRadius: "10px",
};

export default function RegisterModal({
  openModalRegister,
  setopenModalRegister,
  user,
  setUserSelected,
  setRefresh,
}) {
  const { rol } = useMainContext();

  const [open, setOpen] = useState(false);
  const [userType, setUserType] = useState(2);
  const [id, setId] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [nombreFantasia, setnombreFantasia] = useState("");
  const [direccionParticular, setdireccionParticular] = useState("");
  const [direccionTrabajo, setdireccionTrabajo] = useState("");
  const [telefonoParticular, settelefonoParticular] = useState("");
  const [telefonoTrabajo, settelefonoTrabajo] = useState("");
  const [CioRuc, setCioRuc] = useState("");
  const [Cedula, setCedula] = useState("");
  const [Celular, setCelular] = useState("");
  const [incluyeIVA, setIncluyeIVA] = useState(null);
  const [moneda, setMoneda] = useState(null);
  const [descuento, setDescuento] = useState(null);
  const [CodCliente, setCodCliente] = useState("");
  const [estado, setEstado] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [text, setText] = useState("");
  const handleChange = (event) => {
    setUserType(event.target.value);
  };
  const [handleAlert, setHandleAlert] = useState({
    display: false,
    text: "",
    metodo: "",
  });
  const handleRegister = async () => {
    const data = {
      email,
      nombre,
      nombreFantasia,
      direccionParticular,
      direccionTrabajo,
      telefonoParticular,
      telefonoTrabajo,
      CioRuc,
      Cedula,
      Celular,
      CodCliente,
      userType,
      id_rol: userType,
    };

    console.log("data register:", data);

    try {
      const response =
        rol === "Administrador"
          ? await fetchCreateUser(data)
          : await fetchRequestRegister(data);
      console.log("respuseta en front del maiL: ", response);
      if (response.status == 201) {
        setHandleAlert({
          display: true,
          metodo: "success",
          text: response.data.message,
        });
      }
      setRefresh(true);
      return
    } catch (error) {
      let response = error.response;
      console.log("hubo un error: ", error.response);
      setHandleAlert({
        display: true,
        metodo: "error",
        text:
          response?.data?.message || "Hubo un error al registrar el usuario",
      });
    }
    // finally {
    // 	handleOpenModalAlert();
    // }
  };
  const handleClose = () => {
    handleCleanInput();
    setopenModalRegister(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCleanInput = () => {
    setnombreFantasia("");
    setPassword("");
    setEmail("");
    setNombre("");
    setdireccionParticular("");
    setdireccionTrabajo("");
    settelefonoParticular("");
    settelefonoTrabajo("");
    setCioRuc("");
    setCedula("");
    setCelular("");
    setCodCliente("");
    setHandleAlert({
      display: false,
      text: "",
      metodo: "",
    });
    
    if (user) {
      setUserSelected(false);
    }
  };
  const handleUserUpdate = async () => {
    const data = {
      email,
      nombre,
      nombreFantasia,
      direccionParticular,
      direccionTrabajo,
      telefonoParticular,
      telefonoTrabajo,
      CioRuc,
      Cedula,
      Celular,
      CodCliente,
      id,
      estado,
      id_rol: userType,
    };
    console.log("usuario: ", data);
    try {
      const response = await fetchUpdateUser(data);
      if (response.status == 200) {
        setHandleAlert({
          display: true,
          metodo: "success",
          text: response.data.message,
        });
      }
      setRefresh(true);
      return;
    } catch (error) {
      console.log("hubo un error al modificar: ", error);
    }
  };
  const handleOpenModalAlert = () => {
    setText("Se ha enviado un email con la solicitud");
    setModalAlert(true);
  };
  useEffect(() => {
    if (user) {
      console.log("usuario recibido: ", user);
      setId(user.id);
      setNombre(user.Nombre);
      setnombreFantasia(user.nombreFantasia);
      setEmail(user.Email);
      setdireccionParticular(user.DireccionParticular);
      setdireccionTrabajo(user.DireccionTrabajo);
      settelefonoParticular(user.TelefonoParticular);
      settelefonoTrabajo(user.TelefonoTrabajo);
      setCioRuc(user.CioRuc);
      setCedula(user.Cedula);
      setCelular(user.Celular);
      setCodCliente(user.CodCliente);
      setIncluyeIVA(user.incluyeIVA);
      setMoneda(user.Moneda);
      setDescuento(user.setDescuento);
      setUserType(user.id_rol);
      setEstado(user.estado);
    }
  }, [user]);

  return (
    <div>
      <Modal
        open={openModalRegister}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className="headerFormRegister">
              <h2 className="tituloFormRegister">
                {user ? "EDICION DE USUARIO" : "FORMULARIO DE REGISTRO"}
              </h2>
            </Grid>

            <Grid className="containerFormRegister">
              <Grid className="containerInputForm">
                <TextField
                  id="nombre"
                  label="Nombre"
                  value={nombre}
                  fullWidth
                  margin="normal"
                  {...register("nombre", {
                    required: "El nombre es obligatorio",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Solo se permiten letras y espacios",
                    },
                  })}
                  error={!!errors.nombre}
                  helperText={errors.nombre?.message}
                  onChange={(e) => setNombre(e.target.value)}
                />
                <TextField
                  id="nombre fantasia"
                  label="Nombre Fantasía"
                  value={nombreFantasia}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setnombreFantasia(e.target.value)}
                />
                <TextField
                  id="email"
                  label="Email"
                  value={email}
                  fullWidth
                  margin="normal"
                  {...register("email", {
                    required: "El email es obligatorio",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email no válido",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  id="direccion_particular"
                  label="Dirección Particular"
                  value={direccionParticular}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setdireccionParticular(e.target.value)}
                />
                <TextField
                  id="direccion_trabajo"
                  label="Dirección Trabajo"
                  value={direccionTrabajo}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setdireccionTrabajo(e.target.value)}
                />
                <TextField
                  id="telefono_particular"
                  label="Teléfono Particular"
                  value={telefonoParticular}
                  fullWidth
                  margin="normal"
                  onChange={(e) => settelefonoParticular(e.target.value)}
                />
                <TextField
                  id="telefono_trabajo"
                  label="Teléfono Trabajo"
                  value={telefonoTrabajo}
                  fullWidth
                  margin="normal"
                  onChange={(e) => settelefonoTrabajo(e.target.value)}
                />
                <TextField
                  id="CioRuc"
                  label="CioRuc"
                  value={CioRuc}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setCioRuc(e.target.value)}
                />
                <TextField
                  id="Cedula"
                  label="Cédula"
                  value={Cedula}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setCedula(e.target.value)}
                />
                <TextField
                  id="Celular"
                  label="Celular"
                  value={Celular}
                  fullWidth
                  margin="normal"
                  {...register("Celular", {
                    required: "El Celular es obligatorio",
                    min: {
                      value: 1,
                      message: "No puede enviar este campo vacío",
                    },
                  })}
                  error={!!errors.Celular}
                  helperText={errors.Celular?.message}
                  onChange={(e) => setCelular(e.target.value)}
                />
                {rol === "Administrador" && (
                  <>
                    <TextField
                      id="CodCliente"
                      label="CodCliente"
                      value={CodCliente}
                      fullWidth
                      margin="normal"
                      onChange={(e) => setCodCliente(e.target.value)}
                    />
                    <TextField
                      id="IncluyeIVA"
                      label="IVA"
                      value={incluyeIVA}
                      fullWidth
                      margin="normal"
                      onChange={(e) => setIncluyeIVA(e.target.value)}
                    />
                    <TextField
                      id="Moneda"
                      label="Moneda"
                      value={moneda}
                      fullWidth
                      margin="normal"
                      onChange={(e) => setMoneda(e.target.value)}
                    />
                    <TextField
                      id="Descuento"
                      label="Descuento"
                      value={descuento}
                      fullWidth
                      margin="normal"
                      onChange={(e) => setDescuento(e.target.value)}
                    />
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Estado del usuario
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={estado ?? false}
                        label="Estado del usuario"
                        onChange={(e) => {
                          setEstado(e.target.value);
                        }}
                      >
                        <MenuItem sx={{ color: "green" }} value={true}>
                          Habilitado
                        </MenuItem>
                        <MenuItem sx={{ color: "red" }} value={false}>
                          Deshabilitado
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Seleccione el tipo de usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userType ?? 2}
                    label="Seleccione el tipo de usuario"
                    onChange={handleChange}
                  >
                    <MenuItem value={2}>Cliente</MenuItem>
                    <MenuItem value={20}>Proveedor</MenuItem>
                    {rol === "Administrador" && (
                      <MenuItem value={1}>Administrador</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "3%",
              }}
            >
              {handleAlert?.display && (
                <Alert
                  severity={handleAlert?.metodo}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    {handleAlert?.text}
                  </Typography>
                </Alert>
              )}
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid item>
                {user ? (
                  <Button
                    onClick={handleUserUpdate}
                    variant="outlined"
                    sx={{ margin: 5, padding: 2 }}
                  >
                    Modificar
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit(handleRegister)}
                    variant="outlined"
                    sx={{ margin: 5, padding: 2 }}
                  >
                    Registrarme
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{ margin: 5, paddingX: 6, paddingY: 2 }}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ModalAlert
        open={modalAlert}
        handleClose={() => {
          setModalAlert(false);
          handleCleanInput();
          handleClose();
        }}
        text={text}
      />
    </div>
  );
}
