import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

// Función auxiliar para obtener el token
const getToken = () => {
  const token = sessionStorage.getItem("tokenJar");
  if (!token) {
    throw new Error("No se encontró el token de autenticación");
  }
  return token;
};

export const fetchRequestRegister = async (data) => {
  let url = `${apiUrl}/requestRegister`;
  try {
    let response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.log("hubo un error al registrar usuario: ", error);
    throw error;
  }
};
export const fetchCreateUser = async (data) => {
  const token = getToken();
  let url = `${apiUrl}/createUser`;
  try {
    let response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log("hubo un error al registrar usuario: ", error);
    throw error;
  }
};
export const fetchUpdateUser = async (data) => {
  const token = getToken();
  let url = `${apiUrl}/updateUser`;
  try {
    let response = await axios.patch(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log("hubo un error al registrar usuario: ", error);
    throw error;
  }
};
export const FetchAllUsers = async (page) => {
  try {
    const token = getToken(); // Obtenemos el token usando la función auxiliar
    const params = new URLSearchParams();
    params.append("page", page);
    const url = `${apiUrl}/getAllUser?${params}`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error.message === "No se encontró el token de autenticación") {
      // Manejar específicamente el error de token no encontrado
      console.error(
        "Token no disponible. Por favor, inicie sesión nuevamente."
      );
    } else {
      console.error("Error al obtener usuarios:", error);
    }
    throw error;
  }
};
