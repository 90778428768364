import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Grid,
	Modal,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	IconButton,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import useMainContext from '../Context/useMainContext';
const apiUrl = process.env.REACT_APP_API_URL;
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	minHeight: '500px',
	maxWidth: 900,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	maxHeight: '70%',
	overflowY: 'auto',
	borderRadius: '8px',
	justifyContent: 'space-between',
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#E21B1B',
		},
		secondary: {
			main: '#5AB142',
		},
		danger: {
			main: '#EE2F2F',
		},
	},
});

const EditProductModal = ({ open, handleClose, product, imageLoad, handleSave }) => {
	const { setShowMainCarrousel, primaryColor, CodCliente } = useMainContext();
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [price, setPrice] = useState('');
	const [stock, setStock] = useState(0);
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const [selectedSubCategory, setSelectedSubCategory] = useState('');
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [specialCategories, setSpecialCategories] = useState([]);
	const [selectedSpecialCategory, setSelectedSpecialCategory] = useState('');
	const [showSpecialCategory, setShowSpecialCategory] = useState(false);
	const [openModalAvisos, setOpenModalAvisos] = useState(false);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const currency = 'U$S';

	// console.log('PRODUCT:', product);

	const handleChangeSelectedCategory = (event) => {
		setSelectedCategory(event.target.value);
	};

	const handleChangeSelectedSubCategory = (event) => {
		setSelectedSubCategory(event.target.value);
	};

	const handleSpecialCategoryChange = (event) => {
		setSelectedSpecialCategory(event.target.value);
	};

	const handleShowSpecialCategoryChange = (event) => {
		setShowSpecialCategory(event.target.checked);
		if (!event.target.checked) {
			setSelectedSpecialCategory(null);
		}
	};

	const handleCloseOperation = () => {
		handleClose(false);
		setOpenModalAvisos(false);
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
	};
	const handleAlert = (message, color) => {
		setOpenModalAvisos(true);
		setMessage(message);
		setColor(color);
	};

	const handleCleanForm = () => {
		setTitle('');
		setDescription('');
		setPrice('');
		setStock(0);
		setImage(null);
		setSelectedCategory(0);
		setSelectedSubCategory('');
		setCategories([]);
	};

	const handleUpdate = async () => {
		setLoading(true);
		const updatedProduct = {
			...product,
			title,
			description,
			price,
			image,
			stock,
			id_subCategory: selectedSubCategory,
			id_special_category: showSpecialCategory ? selectedSpecialCategory : null,
		};
		try {
			// const response = await fetchUpdateArticle(updatedProduct);
			if (image) {
				console.log('id article', product.id_article);
				const formData = new FormData();
				formData.append('image', image);
				formData.append('id_article', product.id_article);
				await axios.post(`${apiUrl}/upload`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}
			setLoading(false);
			handleAlert('Se actualizó el artículo correctamente', 'primary');
		} catch (error) {
			console.log('Hubo un error', error);
			setLoading(false);
		}
	};

	const handleRegister = async () => {
		setLoading(true);
		const updatedProduct = {
			name: title,
			description,
			price,
			stock,
			id_subCategory: selectedSubCategory,
			id_special_category: showSpecialCategory ? selectedSpecialCategory : null,
		};
		console.log('Create product: ', updatedProduct);
		try {
			setLoading(false);
			handleAlert('Se registra el articulo correctamente', 'primary');
			handleCleanForm();
			// setShowMainCarrousel(true)
		} catch (error) {
			// setLoading(false);
			// handleAlert(error.response, 'warning')
			console.log('Hubo un error', error);
			setLoading(false);
		} finally {
			//     setLoading(false)
			handleAlert('Se registró el artículo correctamente', 'primary');
		}
	};
	const handleDelete = async () => {
		setLoading(true);
		try {
			// await fetchDeleteArticle(product.id_article)
			handleAlert('Se elimino correctamente el producto', 'primary');
			handleCleanForm();
			// setShowMainCarrousel(true)
		} catch (error) {
			console.log('Hubo un error al eliminar el artículo', error);
		} finally {
			setLoading(false);
		}
	};
	const handleGetCategories = async () => {
		setLoading(true);
		try {
			// const response = await fetchCategories();
			// setCategories(response);
		} catch (error) {
			console.log('error');
		} finally {
			setLoading(false);
		}
	};

	const handleGetSubCategories = async (id_category) => {
		setLoading(true);
		try {
			// const response = await fetchSubCategories(id_category);
			// setSubCategories(response);
		} catch (error) {
			console.log('error');
		} finally {
			setLoading(false);
		}
	};

	const handleGetSpecialCategories = async () => {
		setLoading(true);
		try {
			// const response = await fetchSpecialCategories();
			// setSpecialCategories(response);
		} catch (error) {
			console.log('error');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setTitle(product?.name || '');
		setDescription(product?.description || '');
		setPrice(product?.price || '');
		setStock(product?.stock || 0);
	}, [product]);

	useEffect(() => {
		if (open) {
			handleGetCategories();
		}
	}, [open]);

	useEffect(() => {
		if (selectedCategory) {
			handleGetSubCategories(selectedCategory);
		}
	}, [selectedCategory]);

	useEffect(() => {
		// console.log('producto recibido en Edit Product: ', product);
		if (open) {
			handleGetSpecialCategories();
		}
	}, [open]);

	const renderObservation = (observation) => {
		if (!observation) return 'Sin observaciones';
		// Expresión regular para detectar URLs
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		const parts = observation.split(urlRegex);

		return parts.map((part, index) => {
			if (urlRegex.test(part)) {
				return (
					<React.Fragment key={index}>
						<br /> {/* Salto de línea */}
						<strong>Informacion: </strong>
						<a
							href={part}
							target='_blank'
							rel='noopener noreferrer'
							style={{ color: '#1976d2', textDecoration: 'underline' }}
						>
							{part}
						</a>
					</React.Fragment>
				);
			}
			return part;
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				onClick={(e) => e.stopPropagation()}
			>
				<Box
					sx={{
						...style,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
					onClick={(e) => e.stopPropagation()}
				>
					{/* Contenido superior */}
					<Box>
						<Grid
							container
							justifyContent='space-between'
							alignItems='center'
						>
							{product ? (
								<Typography
									id='modal-modal-title'
									variant='h5'
									component='h2'
									gutterBottom
								>
									{product.descripcion}
								</Typography>
							) : null}
							<IconButton
								sx={{ marginBottom: 3 }}
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						</Grid>
						<Grid
							container
							spacing={3}
						>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
								>
									<Box
										// mt={2}
										sx={{
											width: '100%',
											height: 200, // Define el alto de la caja
											backgroundImage: imageLoad
												? `url(${imageLoad})`
												: 'none',
											backgroundSize: 'cover',
											backgroundPosition: 'center',
											backgroundColor: imageLoad
												? 'transparent'
												: 'gray', // Fondo gris si no hay imagen
											borderRadius: 1, // Opcional: bordes redondeados
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											color: imageLoad ? 'transparent' : 'white', // Texto visible solo sin imagen
										}}
									>
										{/* {!product?.image?.url && (
											<Typography variant='body1'>
												Imagen no disponible
											</Typography>
										)} */}
									</Box>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
									>
										<Typography>
											<strong>Observación:</strong>{' '}
											{renderObservation(product.Observacion)}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
									>
										<Typography>
											<strong>Marca: </strong>
											{product?.SupFlia?.Descripcion}
										</Typography>
									</Grid>
									{
										CodCliente !== false && (
											<>
												<Grid
													item
													xs={12}
												>
													<Typography>
														<strong>Precio: </strong>
														{currency} {product.precio}
													</Typography>
												</Grid>
												<Grid
													item
													xs={12}
												>
													<Typography>
														{' '}
														<strong>Descuento:</strong> {product.Descuento}
													</Typography>
												</Grid>
											</>
										)
									}


								</Grid>
							</Grid>
						</Grid>
					</Box>

					{/* Botón Cancelar en la parte inferior derecha */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							mt: 'auto',
							pt: 2,
						}}
					>
						<Button
							variant='outlined'
							color='danger'
							onClick={handleClose}
							sx={{ mr: 2 }}
						>
							Cerrar
						</Button>
					</Box>
				</Box>
			</Modal>
		</ThemeProvider>
	);
};

export default EditProductModal;
