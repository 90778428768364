import React from 'react';
import { useState, useEffect } from 'react';
import Header from './headerContainer';
import Carrousel from './carrousel';
import SectionAliados from '../components/Sections/SectionAliados';
import { Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ProductsCatalog } from '../components/ProductsCatalog';
import Typography from '@mui/joy/Typography';
import { Section } from '../components/Section';
import ContactFooter from '../components/ContactFooter';
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';
import { fetchAllFamilies } from '../Macrosoft_API';
import useMainContext from '../components/Context/useMainContext';
import MenuLatMobile from './MenuLatMobile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginModal from '../components/Login/modalLogin';
import AboutUsModal from '../components/AboutUs';

export const Index = () => {
	const { testContext, setSectionSelected, sectionSelected } = useMainContext();
	const sectionTexts = ['DAHUA', 'Domotica', 'Ofertas'];

	const [articles, setArticles] = useState([]);
	const [product, setProduct] = useState([]);
	const [categories, setCategories] = useState([]);
	const [openModalLogin, setOpenModalLogin] = useState(false);

	const [aboutUs, setAboutUs] = useState(false);

	const handleGetArticles = async () => {
		try {
			const response = await fetchAllFamilies();
			console.log('respuesta desde el fetch:', response);
			if (response?.data) {
				setArticles(response.data);
			}
		} catch (error) {
			console.log('hubo un error en el fetch:', error);
		}
	};
	useEffect(() => {
		handleGetArticles();
	}, []);

	const handleAboutUs = () => {
		setAboutUs(true);
	};

	return (
		<>
			<Grid
				container
				spacing={1}
				className='---INDEX---'
			>
				<Grid
					item
					sx={{
						display: { xs: 'flex', sm: 'none' },
						marginTop: '3%',
						marginLeft: '5%',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<MenuLatMobile />
					<Grid sx={{ display: 'flex', gap: 2, marginRight: '5%' }}>
						<Button
							id='about_us_link'
							sx={{ marginLeft: '-1%' }}
							onClick={handleAboutUs}
						>
							¿Quiénes Somos?
						</Button>
						<ShoppingCartIcon fontSize='large' />
						<AccountCircleIcon
							onClick={() => {
								setOpenModalLogin(true);
							}}
							fontSize='large'
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Header id='header_target' />
				</Grid>

				{sectionSelected === 'ShopNavigator' && <ShopNavigator />}
				{sectionSelected === 'Carrousel' && (
					<Grid>
						<Carrousel setSectionSelected={setSectionSelected} />
					</Grid>
				)}

				<Grid
					className='---PRODUCTOS-DESTACADOS---'
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					sx={{
						color: 'black',
						py: 9,
						mt: '5%',
						boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'bottom',
						marginTop: {
							xs: '3rem',
							sm: '2rem',
							md: '3rem',
							lg: '1%',
						},
					}}
				>
					<Typography
						variant='h1'
						sx={{ color: 'black', fontSize: '2.5rem', marginTop: '50px' }}
					>
						¡Productos Destacados!
					</Typography>
					<Typography
						level='h4'
						color='danger'
						sx={{ mt: 1, fontWeight: 'xl' }}
					>
						{sectionTexts[0]}
					</Typography>
					<ProductsCatalog
						search='DAHUA'
						listaPrecio='1'
					/>
				</Grid>

				<Grid
					className='---SECTION-CONTAINER---'
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					sx={{ marginBottom: '2rem' }}
				>
					<Section />
				</Grid>
				<Divider />
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<SectionAliados />
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<ContactFooter />
				</Grid>
			</Grid>
			<LoginModal
				openModalLogin={openModalLogin}
				setopenModalLogin={setOpenModalLogin}
			/>
			<AboutUsModal
				aboutUs={aboutUs}
				setAboutUs={setAboutUs}
			></AboutUsModal>
		</>
	);
};
