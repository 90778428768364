import * as React from "react";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid, IconButton, TextField, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FetchAllUsers } from "../controller/userController";
import CircularProgress from "@mui/material/CircularProgress";
import RegisterModal from "../modalRegister";
import EditIcon from "@mui/icons-material/Edit";
// import { Edit } from '@mui/icons-material';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import Badge from "@mui/material/Badge";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    success: {
      main: "#82e486",
    },
    error: {
      main: "#e89a95",
    },
  },
});

export default function UserTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [arrayClients, setArrayClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  const [refresh, setRefresh] = useState(false);
  // Filtrar los datos según la búsqueda
  const filteredData = arrayClients.filter((client) =>
    Object.values(client).some((value) =>
      value?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  // Datos de la página actual después del filtrado
  const currentData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reiniciar a la primera página
  };

  const handlGetUsers = async () => {
    setLoading(true);
    try {
      // Reemplaza esto con tu llamada real a la API
      const response = await FetchAllUsers();
      setArrayClients(response.data || []);
    } catch (error) {
      console.error("Hubo un error al obtener los usuarios: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlGetUsers();
  }, []);

  useEffect(() => {
    console.log("Array clients: ", arrayClients);
  }, [arrayClients]);

  const highlightText = (text, search) => {
    if (!search) return text; // Si no hay búsqueda, retorna el texto completo.
    const regex = new RegExp(`(${search})`, "gi"); // Expresión regular para buscar coincidencias.
    return text
      ?.toString()
      .replace(
        regex,
        (match) =>
          `<span style="background-color:rgb(253, 142, 142); font-weight: bold;">${match}</span>`
      );
  };
  const handleUpdateUser = (user) => {
    setUserSelected(user);
    setOpenModalRegister(true);
  };
  useEffect(() => {
    if (refresh) {
      handlGetUsers();
      setRefresh(false);
    }
  }, [refresh]);
  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Grid item xs={12} sm={6} md={7} sx={{ display: "flex" }}>
          <TextField
            sx={{ width: "35%", marginTop: "2rem", marginLeft: "10px" }}
            label="Buscador.."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(0); // Reiniciar a la primera página en cada búsqueda
            }}
          />
          <IconButton sx={{ margin: 2, marginTop: 5 }}>
            <AddIcon
              onClick={() => {
                setUserSelected(false);
                setOpenModalRegister(true);
              }}
            />
          </IconButton>
        </Grid>
        <TableContainer sx={{ maxHeight: "70vh", minHeight: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {[
                  { title: "Nombre Fantasia", icon: <AutoAwesomeIcon /> },
                  { title: "Nombre", icon: <PersonIcon /> },
                  { title: "Direccion Trabajo", icon: <HomeIcon /> },
                  { title: "Direccion Particular", icon: <HomeIcon /> },
                  { title: "Telefono Particular", icon: <PhoneIcon /> },
                  { title: "Telefono Trabajo", icon: <PhoneIcon /> },
                  { title: "Email", icon: <EmailIcon /> },
                  { title: "Estado", icon: <CheckCircleIcon /> },
                  { title: "Gestion", icon: <SettingsIcon /> },
                ].map((header, index, array) => (
                  <TableCell
                    key={header.title}
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      background: "#303030;",
                      fontSize: "1rem",
                      ...(index === 0 && { borderTopLeftRadius: "8px" }),
                      ...(index === array.length - 1 && {
                        borderTopRightRadius: "8px",
                      }),
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {header.icon}
                      <span style={{ marginLeft: "5px" }}>{header.title}</span>
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((client) => (
                <TableRow
                  sx={{
                    fontSize: ".5rem",
                    // Añadimos el color de fondo condicional

                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Color hover estándar de MUI
                    },
                  }}
                  key={client.id}
                >
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.NombreFantasia, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.Nombre, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.DireccionTrabajo, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.DireccionParticular, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.TelefonoParticular, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.TelefonoTrabajo, search),
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: highlightText(client.Email, search),
                    }}
                  />
                  <TableCell>
                    <Badge
                      color={client.estado ? "success" : "error"}
                      badgeContent={client.estado ? "Activo" : "Inactivo"}
                      sx={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      "& .MuiSvgIcon-root": {
                        verticalAlign: "middle",
                      },
                    }}
                  >
                    <EditIcon
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "primary.main",
                          transform: "scale(1.2)",
                        },
                      }}
                      onClick={() => {
                        handleUpdateUser(client);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length} // Total de registros filtrados
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {loading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1300,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
        <RegisterModal
          openModalRegister={openModalRegister}
          setopenModalRegister={setOpenModalRegister}
          user={userSelected}
          setUserSelected={setUserSelected}
          setRefresh={setRefresh}
        />
      </Paper>
    </ThemeProvider>
  );
}
